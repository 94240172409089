import validationEn from "@/js/locales/en/validation";
import validationRo from "@/js/locales/ro/validation";
import common from "@commonLang/messages";
import webOffersRo from "@/js/locales/ro/web_offers";
import webOfferCategoriesRo from "@/js/locales/ro/web_offer_categories";
import jobPostsRo from "@/js/locales/ro/job_posts";
import jobPostCategoriesRo from "@/js/locales/ro/job_post_categories";
import userTimesheetsRo from "@/js/locales/ro/user_timesheets";
import timesheetWorkloadsRo from "@/js/locales/ro/timesheet_workloads";
import doorUserTimesheetsRo from "@/js/locales/ro/door_user_timesheets";

export default {
    en: {
        ...common.en,
        "validation": validationEn,
    },
    ro: {
        ...common.ro,
        "validation": validationRo,
        "web_offers": webOffersRo,
        "web_offer_categories": webOfferCategoriesRo,
        "job_posts": jobPostsRo,
        "job_post_categories": jobPostCategoriesRo,
        "user_timesheets": userTimesheetsRo,
        "timesheet_workloads": timesheetWorkloadsRo,
        "door_user_timesheets": doorUserTimesheetsRo,
    }
}
