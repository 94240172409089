export default {
    "created": "Pontaj usi a fost creat cu succes",
    "updated": "Pontaj usi a fost actualizat cu succes",
    "deleted": "Pontaj usi a fost șters cu succes",
    "restored": "Pontaj usi a fost restaurat cu succes",
    "import_file_not_selected": "Fisierul de import nu a fost selectat",
    "max_size": "Fisierul este prea mare",
    "only_xlsx": "Doar fisierele cu extensie .xlsx sunt acceptate",
    "imported": "Fisierul a fost importat cu succes",
    'confirm_update': 'Esti sigur ca doresti actualizarea?',
    'user_timesheets_updated': 'Pontaj usi actualizat cu succes',
}
