import appOptionsRo from "@commonLang/ro/app_options";
import appOptionCategoriesRo from "@commonLang/ro/app_option_categories";
import documentationsRo from "@commonLang/ro/documentations";
import cronTaskCategoriesRo from "@commonLang/ro/cron_task_categories";
import payuPaymentsRo from "@commonLang/ro/payu_payments";

export default {
    en: {

    },
    ro: {
        'app_options': appOptionsRo,
        'app_option_categories': appOptionCategoriesRo,
        'documentations': documentationsRo,
        'cron_task_categories': cronTaskCategoriesRo,
        'payu_payments': payuPaymentsRo
    }
}
